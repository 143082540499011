



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import { Debounce } from "vue-debounce-decorator";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {}
})
export default class FormularioFestival extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private documento: File | null = null;
  private invalidFile: boolean = true;
  private customModel = "no";
  private verModalIntegrante: boolean = false;
  private verModalAutor: boolean = false;
  private verModalCompositor: boolean = false;
  
  private dataIntegrante: any = {
    rut: "",
    nombre_completo: ""
    
  };
  
  private dataAutor: any = {
    rut: "",
    nombre_completo: "",
    fecha_nacimiento: "",
    direccion: "",
    ciudad: "",
    telefono: "",
    email: ""
  };
 
  private dataCompositor: any = {
    rut: "",
    nombre_completo: "",
    fecha_nacimiento: "",
    direccion: "",
    ciudad: ""
  };
  
  private rutIntegrante: string = "";
  private rutAutor: string = "";
  private rutCompositor: string = "";
  
  private dataForm: any = {
    tipo:"2",
    pseudonimo_cancion: "",
    titulo_cancion: "",
    genero_cancion: "",
    tiempo_cancion: "",
    ciudad_cancion: "",
    pais_cancion: "",
    nombre_grupo: "",
    cancion: "",
    mensaje_cancion: "",
    pais_grupo: "",
    ciudad_grupo: "",
    integrantes_string: "",
    autores_string: "",
    compositores_string: "",
    integrantes: [],
    autores: [],
    compositores: []
  };

  private mounted() {
    document.title =
      "Ficha de Inscripción XXX Pre-Festival Folclórico en la patagonia 2024";
  }
  private agregarIntegrante() {
    this.dataIntegrante = {
      nombre_completo: "",
      rut: ""
    };
    this.rutIntegrante = "";
    this.verModalIntegrante = true;
  }
  private agregarAutor() {
    this.dataAutor = {
      nombre_completo: "",
      telefono: "",
      email: "",
      fecha_nacimiento: "",
      direccion: "",
      ciudad: "",
      rut: ""
    };
    this.rutAutor = "";
    this.verModalAutor = true;
  }
  private agregarCompositor() {
    this.dataCompositor = {
      nombre_completo: "",
      telefono: "",
      email: "",
      fecha_nacimiento: "",
      direccion: "",
      ciudad: "",
      rut: ""
    };
    this.rutCompositor = "";
    this.verModalCompositor = true;
  }
  private eliminarIntegrante(rut: string) {
    if (rut && rut != "") {
      let index: any = null;
      for (let i = 0; i < this.dataForm.integrantes.length; i++) {
        if (this.dataForm.integrantes[i].rut == rut) {
          index = i;
          break;
        }
      }
      if (index != null) {
        this.dataForm.integrantes.splice(index, 1);
      }
    }
  }
  private eliminarAutor(rut: string) {
    if (rut && rut != "") {
      let index: any = null;
      for (let i = 0; i < this.dataForm.autores.length; i++) {
        if (this.dataForm.autores[i].rut == rut) {
          index = i;
          break;
        }
      }
      if (index != null) {
        this.dataForm.autores.splice(index, 1);
      }
    }
  }
  private eliminarCompositor(rut: string) {
    if (rut && rut != "") {
      let index: any = null;
      for (let i = 0; i < this.dataForm.compositores.length; i++) {
        if (this.dataForm.compositores[i].rut == rut) {
          index = i;
          break;
        }
      }
      if (index != null) {
        this.dataForm.compositores.splice(index, 1);
      }
    }
  }
  private validarIntegrante() {
    if (this.dataIntegrante.rut.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un C.I válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataIntegrante.nombre_completo.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un nombre.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    for (let i = 0; i < this.dataForm.integrantes.length; i++) {
      if (this.dataForm.integrantes[i].rut == this.dataIntegrante.rut) {
        Notify.create({
          color: "negative",
          message: "El C.I ya ha sido registrado como intérprete.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      }
    }
    this.dataForm.integrantes.push(this.dataIntegrante);
    this.verModalIntegrante = false;
  }
  private validarAutor() {
    if (this.dataAutor.rut.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un C.I válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataAutor.nombre_completo.trim() == "" || this.dataAutor.nombre_completo.length < 3) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el nombre completo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataAutor.fecha_nacimiento == "" || this.dataAutor.fecha_nacimiento.length != 10) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la fecha de nacimiento.(xx-xx-xxxx)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataAutor.direccion.trim() == "" || this.dataAutor.direccion.length < 3) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataAutor.ciudad.trim() == "" || this.dataAutor.ciudad.length < 3) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la ciudad.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataAutor.telefono.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el télefono",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }  else if (this.dataAutor.telefono.length < 9 ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataAutor.email.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el Email.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.dataAutor.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    for (let i = 0; i < this.dataForm.autores.length; i++) {
      if (this.dataForm.autores[i].rut == this.dataAutor.rut) {
        Notify.create({
          color: "negative",
          message: "El C.I ya ha sido registrado como autor.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      }
    }
    this.dataForm.autores.push(this.dataAutor);
    this.verModalAutor = false;
  }
  private validarCompositor() {
    if (this.dataCompositor.rut.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un C.I válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataCompositor.nombre_completo.trim() == "" || this.dataCompositor.nombre_completo.length < 3) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el nombre completo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataCompositor.fecha_nacimiento == "" || this.dataCompositor.fecha_nacimiento.length != 10) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la fecha de nacimiento.(xx-xx-xxxx)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataCompositor.direccion.trim() == "" || this.dataCompositor.direccion.length < 3) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataCompositor.ciudad.trim() == "" || this.dataCompositor.ciudad.length < 3) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la ciudad.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } 
    for (let i = 0; i < this.dataForm.compositores.length; i++) {
      if (this.dataForm.compositores[i].rut == this.dataCompositor.rut) {
        Notify.create({
          color: "negative",
          message: "El C.I ya ha sido registrado como compositor.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      }
    }
    this.dataForm.compositores.push(this.dataCompositor);
    this.verModalCompositor = false;
  }
  private checkFile(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      flag = true;
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        this.invalidFile = true;
        Notify.create({
          color: "negative",
          message: "El archivo excede el máximo (5mb).",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      } else if (target.files[0].size <= 0 || !flag) {
        this.invalidFile = true;
      } else {
       if (target.name === "documento") {
          this.documento = target.files[0];
        }
        this.invalidFile = false;
      }
    }
  }
  private enviar() {
    if (this.dataForm.pseudonimo_cancion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Pseudónimo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }else if (this.dataForm.titulo_cancion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Título de la Canción.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.genero_cancion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el género Musical.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.tiempo_cancion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el tiempo de duración de la Canción.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.ciudad_cancion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la Ciudad de la Canción.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.pais_cancion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el País de la Canción.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.autores.length == 0) {
      Notify.create({
        color: "negative",
        message: "Por favor, debe ingresar al menos un autor.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.compositores.length == 0) {
      Notify.create({
        color: "negative",
        message: "Por favor, debe ingresar al menos un compositor.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.integrantes.length == 0) {
      Notify.create({
        color: "negative",
        message: "Por favor, debe ingresar al menos un intérprete.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;  
    } else if (this.dataForm.nombre_grupo == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el Nombre del Grupo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      }); 
      return; 
    } else if (this.dataForm.mensaje_cancion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el Mensaje de la canción.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      }); 
      return;
    } else if (this.dataForm.ciudad_grupo == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la Ciudad del grupo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      }); 
      return;
    } else if (this.dataForm.pais_grupo == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el País del grupo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      }); 
      return;
    } else if (this.dataForm.cancion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la letra de la canción.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      }); 
      return;  
    } else if (this.documento == null) {
      Notify.create({
        color: "negative",
        message: "Por favor, debe adjuntar la canción.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.customModel == "no" || !this.customModel) {
      Notify.create({
        color: "negative",
        message: "Para continuar, debe aceptar las condiciones mencionadas.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });

    const data = new FormData();
    if (this.documento) {
      const ciFile = this.documento as Blob;
      data.append("documento", ciFile);
    } else {
      const ciFile = new File(["file"], "documento") as Blob;
      data.append("documento", ciFile);
    }
    this.dataForm.integrantes_string = JSON.stringify(
      this.dataForm.integrantes
    );
    this.dataForm.autores_string = JSON.stringify(
      this.dataForm.autores
    );
    this.dataForm.compositores_string = JSON.stringify(
      this.dataForm.compositores
    );
    for (const prop in this.dataForm) {
      if (this.dataForm.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataForm[prop] || this.dataForm[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataForm[prop]);
        }
      }
    }
    this.$axios
      .post("formulario/festival", data, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente el formulario.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.irPagina("home");
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un error, por favor intente nuevamente.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ordenanzas-municipales") {
      this.routerGo("Ordenanzas");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }
  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }

}
