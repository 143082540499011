


















































































































































































































































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import { Debounce } from "vue-debounce-decorator";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {}
})
export default class FormularioCarnaval extends Mixins(FormValidator) {
  private win = window;
  private cerrado = true;
  private base = window.location;
  private tipoOptions: SelectOption[] = [
    { value: "1", label: "Inscripción Adulto" },
    { value: "2", label: "Inscripción Menor de edad (Chapuzón Kids)" }
  ];
  private dataTipo: SelectOption = {
    value: null,
    label: "Tipo de inscripción"
  };

  private dataForm: any = {
    tipo: 2,
    telefono: "",
    email: "",
    nombre: "",
    rut: "",
    nombre_representante: "",
    rut_representante: "",
    direccion: "",
    fecha_nacimiento: "",
    ciudad: "",
    pais: ""
  };
  private customModel = "no";
  private formattedFecha = "";

  private carnet: File | null = null;
  private invalidFile: boolean = true;

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  @Watch("formattedFecha")
  @Debounce(1000)
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    if (birthDate && birthDate.length <= 18) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      this.dataForm.fecha_nacimiento = null;
      return;
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.dataForm.fecha_nacimiento = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.dataForm.fecha_nacimiento = new Date(birthDate);
    }
  }
  @Watch("dataTipo")
  private tipoWatcher(newValue: any) {
    this.dataForm.tipo = newValue.value;
  }

  // get age() {
  //   //Se calcula en base a la fecha del evento
  //   const today = new Date(2024, 1, 17);
  //   const date = this.dataForm.fecha_nacimiento;
  //   if (date) {
  //     const age = (today.getTime() - date.getTime()) / (1000 * 3600 * 24 * 365);
  //     return age;
  //   }
  //   return 0;
  // }
  get age() {
    // Obtén la fecha a comparar
    var fechaActual = new Date(2024, 1, 17);

    // Resta el año de nacimiento al año actual
    var edad = fechaActual.getFullYear() - this.dataForm.fecha_nacimiento.getFullYear();

    // Verifica si aún no ha pasado el día y el mes de cumpleaños
    if (
      fechaActual.getMonth() < this.dataForm.fecha_nacimiento.getMonth() ||
      (fechaActual.getMonth() === this.dataForm.fecha_nacimiento.getMonth() && fechaActual.getDate() < this.dataForm.fecha_nacimiento.getDate())
    ) {
      // Si no ha cumplido años, resta 1 a la edad
      edad--;
    }

    return edad;
  }

  private mounted() {
    document.title =
      "Chapuzón Kids en el Estrecho 2024 - Ilustre Municipalidad de Punta Arenas";
    // Validamos la fecha actual para cerrar el formulario
    const fechaActual = new Date();

    // Formatear la fecha actual como "YYYY-MM-DD"
    const añoActual = fechaActual.getFullYear();
    const mesActual = String(fechaActual.getMonth() + 1).padStart(2, "0"); // Meses son de 0 a 11
    const diaActual = String(fechaActual.getDate()).padStart(2, "0");

    const fechaFormateada = `${añoActual}-${mesActual}-${diaActual}`;

    // Comparar con la fecha deseada
    const fechaDeseada = "2024-02-15";

    // Convertir las fechas a objetos Date para la comparación
    const fechaActualObj = new Date(fechaFormateada);
    const fechaDeseadaObj = new Date(fechaDeseada);

    if (fechaActualObj >= fechaDeseadaObj) {
      this.cerrado = true;
    }
  }

  private checkFileCarnet(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        Notify.create({
          color: "negative",
          message: "El archivo excede el máximo (5mb).",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.carnet = null;
        return;
      } else if (target.files[0].size <= 0 || !flag) {
        this.carnet = null;
      } else {
        this.carnet = target.files[0];
      }
    } else {
      this.carnet = null;
    }
  }

  private enviar() {
    if (!this.dataForm.tipo || this.dataForm.tipo == 0) {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione un tipo de inscripción.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.rut == "") {
      let mensaje = "Por favor ingrese un C.I válido.";
      if (this.dataForm.tipo == 2) {
        mensaje = "Por favor ingrese un C.I del menor válido.";
      }
      Notify.create({
        color: "negative",
        message: mensaje,
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.nombre.trim() == "" ||
      this.dataForm.nombre.length < 3
    ) {
      let mensaje = "Por favor ingrese un nombre válido.";
      if (this.dataForm.tipo == 2) {
        mensaje = "Por favor ingrese un nombre del menor válido.";
      }
      Notify.create({
        color: "negative",
        message: mensaje,
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.fecha_nacimiento == "" ||
      this.dataForm.fecha_nacimiento == null ||
      !this.checkFecha(this.dataForm.fecha_nacimiento)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.dataForm.fecha_nacimiento.getFullYear() < 1890) {
          Notify.create({
            color: "negative",
            message:
              "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      }
    }
    if (this.dataForm.tipo == 2 && (this.age > 12 || this.age < 6)) {
      Notify.create({
        color: "negative",
        message:
          "El menor debe tener entre 6 y 12 años de edad a la fecha del evento, ingrese una nueva fecha de nacimiento.",
        icon: "report_problem",
        position: "top",
        timeout: 4000
      });
      return;
    } else if (this.dataForm.tipo == 1 && this.age < 18) {
      Notify.create({
        color: "negative",
        message:
          "El adulto no puede ser menor de 18 años, ingrese una nueva fecha de nacimiento.",
        icon: "report_problem",
        position: "top",
        timeout: 4000
      });
      return;
    } else if (
      this.dataForm.tipo == 2 &&
      this.dataForm.rut_representante == ""
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un C.I válido del representante legal.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.tipo == 2 &&
      this.dataForm.rut_representante == this.dataForm.rut
    ) {
      Notify.create({
        color: "negative",
        message: "El C.I del representante debe ser distinto al C.I del menor.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.tipo == 2 &&
      (this.dataForm.nombre_representante.trim() == "" ||
        this.dataForm.nombre_representante.length < 3)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un nombre del representante legal.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.direccion.trim() == "" ||
      this.dataForm.direccion.length < 3
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un domicilio.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.ciudad.trim() == "" ||
      this.dataForm.ciudad.length < 3
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la Ciudad.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.pais.trim() == "" ||
      this.dataForm.pais.length < 3
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el País.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.telefono == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.dataForm.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.tipo == 2 && this.carnet == null) {
      Notify.create({
        color: "negative",
        message: "Por favor, adjunte un C.I válido (5mb max.).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.customModel == "no" || !this.customModel) {
      Notify.create({
        color: "negative",
        message: "Para continuar, debe aceptar las condiciones mencionadas.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });

    const data = new FormData();
    if (this.carnet) {
      const ciFile = this.carnet as Blob;
      data.append("carnet", ciFile);
    } else {
      const ciFile = new File(["file"], "Carnet") as Blob;
      data.append("carnet", ciFile);
    }

    for (const prop in this.dataForm) {
      if (this.dataForm.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataForm[prop] || this.dataForm[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataForm[prop]);
        }
      }
    }

    this.$axios
      .post("formulario/chapuzon", data, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente el formulario de inscripción.",
          icon: "report_problem",
          position: "top",
          timeout: 4000
        });
        this.$router.push({
          name: "ReservaChapuzon2024",
          params: { codigo: res.data.codigo }
        });
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        if (err.response.data.message == "REGISTRADO") {
          let mensaje = "El C.I ingresado ya cuenta con un registro.";
          if (this.dataForm.tipo == 2) {
            mensaje = "El C.I del menor ingresado ya cuenta con un registro.";
          }
          Notify.create({
            color: "negative",
            message: mensaje,
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else {
          if (err.response.data.message == "CUPOS") {
            let mensaje = "Lo sentimos, se han agotado los cupos para esta actividad.";
            Notify.create({
              color: "negative",
              message: mensaje,
              icon: "report_problem",
              position: "top",
              timeout: 2500
            });
            return;
          } else {
            Notify.create({
              color: "negative",
              message: "Ha ocurrido un error, por favor intente nuevamente.",
              icon: "report_problem",
              position: "top",
              timeout: 2500
            });
            return;
          }
        }
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ordenanzas-municipales") {
      this.routerGo("Ordenanzas");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }

  private checkFecha(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
