




































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {}
})
export default class FormularioCarnaval extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private comunaOptions: SelectOption[] = [];
  private cityList: any[] = [];
  private dataComuna: SelectOption = {
    value: null,
    label: " "
  };
  private declaracion: File | null = null;
  private permiso: File | null = null;
  private invalidFile: boolean = true;
  private selectionCategoria: any = [];
  private customModel = "no";
  private verModalIntegrante: boolean = false;
  private dataIntegrante: any = {
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    rut: ""
  };
  private rutIntegrante: string = "";
  private dataForm: any = {
    comuna_id: 0,
    telefono: "",
    email: "",
    nombre: "",
    direccion: "",
    coordinador: "",
    telefono_coordinador: "",
    email_coordinador: "",
    tematica: "",
    nombre_alegoria: "",
    descripcion_tematica: "",
    descripcion_ambiente: "",
    descripcion_regional: "",
    integrantes_string: "",
    integrantes: []
  };

  @Watch("dataComuna")
  private comunaWatcher(newValue: any) {
    this.dataForm.comuna_id = newValue.value;
  }
  @Watch("selectionCategoria")
  private categoriaWatcher(newValue: any) {
    console.log(newValue);
  }

  @Watch("rutIntegrante")
  private formatRutIntegrante(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.dataIntegrante.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.rutIntegrante = `${rut}-${dv}`;
    } else {
      this.rutIntegrante = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  private mounted() {
    document.title =
      "Solicitud Oficial de Inscripción en el Carnaval de Invierno 2024 - Ilustre Municipalidad de Punta Arenas";
    this.getComunas();
  }

  private agregarIntegrante() {
    this.dataIntegrante = {
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      rut: ""
    };
    this.rutIntegrante = "";
    this.verModalIntegrante = true;
  }
  private eliminarIntegrante(rut: string) {
    if (rut && rut != "") {
      let index: any = null;
      for (let i = 0; i < this.dataForm.integrantes.length; i++) {
        if (this.dataForm.integrantes[i].rut == rut) {
          index = i;
          break;
        }
      }
      if (index != null) {
        this.dataForm.integrantes.splice(index, 1);
      }
    }
  }
  private filterFnComuna(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.comunaOptions = this.cityList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  private getComunas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios("comunas")
      .then(res => {
        res.data.forEach((city: any) => {
          this.cityList.push({
            value: city.id,
            label: city.nombre
          });
          if (this.dataForm.comuna_id === -1 && this.dataForm.comuna) {
            this.cityList.push({
              value: this.dataForm.comuna_id,
              label: this.dataForm.comuna
            });
          } else if (this.dataForm.comuna_id === -1) {
            this.dataForm.comuna_id = null;
          }
          if (city.id == this.dataForm.comuna_id) {
            this.dataComuna = {
              value: city.id,
              label: city.nombre
            };
          }
        });
        this.$q.loading.hide();
      })
      .catch(err => {
        this.$q.loading.hide();
      });
  }

  private validarIntegrante() {
    if (
      this.dataIntegrante.rut.trim() == "" ||
      !this.validateRut(this.dataIntegrante.rut)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataIntegrante.nombre.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un nombre.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataIntegrante.apellido_paterno.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un apellido paterno.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataIntegrante.apellido_materno.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un apellido materno.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    for (let i = 0; i < this.dataForm.integrantes.length; i++) {
      if (this.dataForm.integrantes[i].rut == this.dataIntegrante.rut) {
        Notify.create({
          color: "negative",
          message: "El rut ya ha sido registrado como integrante.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      }
    }
    this.dataForm.integrantes.push(this.dataIntegrante);
    this.verModalIntegrante = false;
  }

  private checkFile(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        this.invalidFile = true;
        Notify.create({
          color: "negative",
          message: "El archivo excede el máximo (5mb).",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      } else if (target.files[0].size <= 0 || !flag) {
        this.invalidFile = true;
      } else {
        if (target.name === "permiso") {
          this.permiso = target.files[0];
        } else if (target.name === "declaracion") {
          this.declaracion = target.files[0];
        }
        this.invalidFile = false;
      }
    }
  }

  private enviar() {
    if (this.dataForm.nombre.trim() == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese un nombre de la institución a la cual representa.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.direccion.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      !this.dataForm.comuna_id == null ||
      this.dataForm.comuna_id == null ||
      this.dataForm.comuna_id == 0
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione una comuna.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.dataForm.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.telefono == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.dataForm.coordinador || this.dataForm.coordinador == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un(a) Coordinador(a).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.email_coordinador == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el Correo del coordinador(a).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.dataForm.email_coordinador)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo del coordinador(a) válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.telefono_coordinador == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Teléfono del coordinador(a).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.telefono_coordinador.length < 9) {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese un teléfono del coordinador(a) válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.tematica == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese una temática o nombre que representa el carro alegórico.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.nombre_alegoria == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un nombre de la alegoría.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.selectionCategoria.length == 0) {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione al menos 1 categoría de participación.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.descripcion_tematica == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese un una descripción de la temática que representa.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.descripcion_ambiente == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese un una descripción de elementos de cuidado del medio ambiente.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.descripcion_regional == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese un una relación con la temática de identidad regional.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.integrantes.length == 0) {
      Notify.create({
        color: "negative",
        message: "Por favor, debe ingresar al menos 1 integrante.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.customModel == "no" || !this.customModel) {
      Notify.create({
        color: "negative",
        message: "Para continuar, debe aceptar las condiciones mencionadas.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });

    const data = new FormData();
    if (this.declaracion) {
      const ciFile = this.declaracion as Blob;
      data.append("declaracion", ciFile);
    } else {
      const ciFile = new File(["file"], "Declaracion") as Blob;
      data.append("declaracion", ciFile);
    }
    if (this.permiso) {
      const pFile = this.permiso as Blob;
      data.append("permiso", pFile);
    } else {
      const pFile = new File(["file"], "Permiso") as Blob;
      data.append("permiso", pFile);
    }
    this.dataForm.integrantes_string = JSON.stringify(
      this.dataForm.integrantes
    );
    for (const prop in this.dataForm) {
      if (this.dataForm.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataForm[prop] || this.dataForm[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataForm[prop]);
        }
      }
    }
    data.append("categorias", this.selectionCategoria);
    this.$axios
      .post("formulario/carnaval", data, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente el formulario.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.irPagina("home");
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un error, por favor intente nuevamente.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ordenanzas-municipales") {
      this.routerGo("Ordenanzas");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
