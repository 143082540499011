


























































































































































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import { Debounce } from "vue-debounce-decorator";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {}
})
export default class FormularioNavidad extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private poblacionesOptions: SelectOption[] = [];
  private dataPoblaciones: SelectOption = {
    value: null,
    label: "Seleccione población, de no estar su población coloque 'OTRA' "
  };
  private dataGenero: SelectOption = {
    value: null,
    label: "Seleccione el género"
  };
  private formattedRut: string = "";
  private formattedRutRepresentante: string = "";
  private dataForm: any = {
    id_poblacion: 0,
    telefono: "",
    email: "",
    nombre: "",
    rut: "",
    genero: 0,
    nombre_representante: "",
    rut_representante: "",
    fecha_nacimiento_representante: "",
    direccion: "",
    fecha_nacimiento: ""
  };
  private customModel = "no";
  private formattedFecha = "";
  private formattedFechaR = "";
  private generoOptions: Array<SelectOption> = [
    { value: 1, label: "Masculino" },
    { value: 2, label: "Femenino" },
    { value: 3, label: "Otro" },
    { value: 4, label: "Prefiero no decirlo" },
    { value: 5, label: "No binario" }
  ];

  private documentacion: File | null = null;
  private invalidFile: boolean = true;

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  @Watch("formattedFecha")
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    if (birthDate && birthDate.length <= 18) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      this.dataForm.fecha_nacimiento = null;
      return;
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.dataForm.fecha_nacimiento = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.dataForm.fecha_nacimiento = new Date(birthDate);
    }
  }

  @Watch("formattedFechaR")
  private edadRWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    if (birthDate && birthDate.length <= 18) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      this.dataForm.fecha_nacimiento_representante = null;
      return;
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.dataForm.fecha_nacimiento_representante = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.dataForm.fecha_nacimiento_representante = new Date(birthDate);
    }
  }


  @Watch("dataPoblaciones")
  private PoblacionWatcher(newValue: any) {
    this.dataForm.id_poblacion = newValue.value;
  }

  @Watch("dataGenero")
  private GeneroWatcher(newValue: any) {
    this.dataForm.genero = newValue.value;
  }
  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.dataForm.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  @Watch("formattedRutRepresentante")
  private formatRutRepresentante(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.dataForm.rut_representante = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRutRepresentante = `${rut}-${dv}`;
    } else {
      this.formattedRutRepresentante = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  private age() {
    let today = new Date(2024, 11, 31);
    let fechaNacimiento = this.dataForm.fecha_nacimiento;
    if (fechaNacimiento && fechaNacimiento != null) {
      let edad = today.getFullYear() - fechaNacimiento.getFullYear();
      let diferenciaMeses = today.getMonth() - fechaNacimiento.getMonth();
      if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && today.getDate() < fechaNacimiento.getDate())
      ) {
        edad--;
      }
      return edad;
    }else{
      return 0;
    }
  }

  private ageHoy() {
    let today = new Date(Date.now());
    let fechaNacimiento = this.dataForm.fecha_nacimiento_representante;

    if (fechaNacimiento && fechaNacimiento != null) {
      let edad = today.getFullYear() - fechaNacimiento.getFullYear();
      let diferenciaMeses = today.getMonth() - fechaNacimiento.getMonth();
      if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && today.getDate() < fechaNacimiento.getDate())
      ) {
        edad--;
      }
      return edad;
    }else{
      return 0;
    }
  }


  private mounted() {
    document.title =
      "Postulación Navidad - Ilustre Municipalidad de Punta Arenas";
    this.getPoblaciones();
  }

  private checkFile(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        this.invalidFile = true;
        Notify.create({
          color: "negative",
          message: "El archivo excede el máximo (5mb).",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.documentacion = null;
        return;
      } else if (target.files[0].size <= 0 || !flag) {
        this.invalidFile = true;
      } else {
        this.documentacion = target.files[0];
        this.invalidFile = false;
      }
    }
  }

  private enviar() {
    if (
      this.dataForm.rut == "" ||
      this.validateRut(this.dataForm.rut) == false
    ) {
      let mensaje = "Por favor ingrese un Rut válido.";
      if (this.dataForm.tipo == 2) {
        mensaje = "Por favor ingrese un Rut de la niña o niño válido.";
      }
      Notify.create({
        color: "negative",
        message: mensaje,
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.nombre.trim() == "" ||
      this.dataForm.nombre.length < 3
    ) {
      let mensaje = "Por favor ingrese un nombre válido.";
      if (this.dataForm.tipo == 2) {
        mensaje = "Por favor ingrese un nombre de la niña o niño válido.";
      }
      Notify.create({
        color: "negative",
        message: mensaje,
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.rut_representante == "" ||
      this.validateRut(this.dataForm.rut_representante) == false
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido del responsable.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.rut_representante == this.dataForm.rut) {
      Notify.create({
        color: "negative",
        message:
          "El rut del responsable debe ser distinto al rut de la niña o niño.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.nombre_representante.trim() == "" ||
      this.dataForm.nombre_representante.length < 3
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un nombre del responsable.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.direccion.trim() == "" ||
      this.dataForm.direccion.length < 3
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un domicilio.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.id_poblacion == 0) {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione una población.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.genero == 0) {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione el género de la niña o niño.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;  
    } else if (this.dataForm.telefono == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.dataForm.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.fecha_nacimiento == "" ||
      this.dataForm.fecha_nacimiento == null ||
      !this.checkFecha(this.dataForm.fecha_nacimiento)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.fecha_nacimiento_representante == "" ||
      this.dataForm.fecha_nacimiento_representante == null ||
      !this.checkFecha(this.dataForm.fecha_nacimiento_representante)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;  
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.dataForm.fecha_nacimiento.getFullYear() < 1890) {
          Notify.create({
            color: "negative",
            message:
              "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      }
    }

    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.dataForm.fecha_nacimiento_representante.getFullYear() < 1890) {
          Notify.create({
            color: "negative",
            message:
              "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      }
    }

    if (this.age() >= 11) {
      Notify.create({
        color: "negative",
        message: "El niño o niña debe ser menor a 11 años al 31/12/2024.",
        icon: "report_problem",
        position: "top",
        timeout: 4000
      });
      return;
    } else if (this.ageHoy() < 18) {
      Notify.create({
        color: "negative",
        message: "El responsable debe ser mayor de 18 años",
        icon: "report_problem",
        position: "top",
        timeout: 4000
      });
      return;
    } else if (this.documentacion == null) {
      Notify.create({
        color: "negative",
        message:
          "Por favor, una cédula de identidad o certificado de nacimiento válido (5mb max.).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });

    const data = new FormData();
    if (this.documentacion) {
      const ciFile = this.documentacion as Blob;
      data.append("documentacion", ciFile);
    } else {
      const ciFile = new File(["file"], "documentacion") as Blob;
      data.append("documentacion", ciFile);
    }
    for (const prop in this.dataForm) {
      if (this.dataForm.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataForm[prop] || this.dataForm[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataForm[prop]);
        }
      }
    }

    this.$axios
      .post("formulario/navidad", data, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente el formulario de inscripcion.",
          icon: "report_problem",
          position: "top",
          timeout: 4000
        });
        this.routerGo("Home");
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        if (err.response.data.message == "REGISTRADO") {
          let mensaje = "El Rut ingresado ya cuenta con un registro.";
          Notify.create({
            color: "negative",
            message: mensaje,
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else {
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un error, por favor intente nuevamente.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      });
  }

  private getPoblaciones() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("poblaciones")
      .then(res => {
        this.poblacionesOptions = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.poblacionesOptions = [];
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ordenanzas-municipales") {
      this.routerGo("Ordenanzas");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }

  private checkFecha(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
